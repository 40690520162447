import {
  CategoryService,
  BrandService,
  ProductService,
  DealService,
} from "../sk-services";
import { CommonService } from "@sk/services";

class PoData {
  static async updateProductDetails(products) {
    // Extract unique IDs
    const categoryIds = [
      ...new Set(products.map((p) => p.categoryId).filter((id) => id)),
    ];
    const brandIds = [
      ...new Set(products.map((p) => p.brandId).filter((id) => id)),
    ];
    const productIds = [...new Set(products.map((p) => p.productId))];

    // Fetch category data
    const categoryData = await CategoryService.getList({
      page: 1,
      count: categoryIds.length,
      filter: { _id: { $in: categoryIds } },
      select: "name",
    });

    // Fetch brand data
    const brandData = await BrandService.getList({
      page: 1,
      count: brandIds.length,
      filter: { _id: { $in: brandIds } },
      select: "name",
    });

    // Fetch product details
    const productDetails = await ProductService.getList({
      page: 1,
      count: productIds.length,
      filter: { _id: { $in: productIds } },
      select: "name,dealer_price,tax,mrp",
    });

    // Fetch deal data
    const dealsData = await DealService.getDeals({
      page: 1,
      count: 1000,
      filter: {
        dealType: { $ne: "Combo" },
        product: {
          $elemMatch: { id: { $in: productIds }, quantity: { $eq: 1 } },
        },
        is_active: true,
      },
      select: "name,product,b2bPrice",
    });

    // Fetch warehouse price config (assuming whId is passed or available globally; adjust as needed)
    const whId = products[0]?.whId || "defaultWhId"; // Replace with actual whId source
    const dealIds = dealsData?.resp?.map((d) => d._id) || [];
    let warehousePriceData = { resp: [] };
    if (dealIds.length) {
      warehousePriceData = await DealService.getWarehouseConfig({
        page: 1,
        count: dealIds.length,
        filter: {
          dealId: { $in: dealIds },
          isActive: true,
          whId: whId,
        },
        sort: "-createdAt",
        select: "margin",
      });
    }

    // Map and update product data
    const productData = products.map((p) => {
      const category = categoryData?.resp?.find((c) => c._id === p.categoryId);
      const brand = brandData?.resp?.find((b) => b._id === p.brandId);
      const product = productDetails?.resp?.find(
        (pr) => pr._id === p.productId
      );
      const deal = dealsData?.resp?.find((d) =>
        d.product.some((pr) => pr.id === p.productId)
      );
      const warehousePrice = deal
        ? warehousePriceData?.resp?.find((wp) => wp.dealId === deal._id)
        : null;

      // Calculate B2B Price
      let _b2bPrice = null;
      const mrp = product?.mrp || p.mrp;
      if (deal) {
        if (warehousePrice && warehousePrice.margin) {
          _b2bPrice = mrp - mrp * (warehousePrice.margin / 100);
        } else {
          _b2bPrice = deal.b2bPrice;
        }
      }

      // Calculate Purchase Price (mathUnitPrice logic)
      let priceToCompute =
        product?.dealer_price && p.marginDealerPrice
          ? product.dealer_price
          : mrp;
      const originalPrice = priceToCompute;

      if (p.margins?.bMargin > 0) {
        priceToCompute *= 1 - p.margins.bMargin / 100;
      }

      if (p.margins?.sMarginType === "%") {
        if (p.margins?.sMargin) {
          priceToCompute *= 1 - p.margins.sMargin / 100;
        }
      } else {
        if (p.margins?.sMargin) {
          priceToCompute -= p.margins.sMargin;
        }
      }

      const _purchasePrice = CommonService.roundedByDecimalPlace(
        priceToCompute,
        2
      );
      const finalPurchasePrice = _purchasePrice < 0 ? 0 : _purchasePrice;

      const _finalMargin = CommonService.roundedByDecimalPlace(
        ((originalPrice - finalPurchasePrice) / originalPrice) * 100,
        2
      );

      // Calculate Sub Total (mathTotalValue logic)
      const _subTotal = (p.quantity?.requested || 0) * finalPurchasePrice;
      const finalSubTotal = _subTotal < 0 ? 0 : _subTotal;

      // Return updated product with relevant keys
      return {
        ...p,
        _category: category,
        _brand: brand,
        _product: product,
        _requestedQty: p.quantity?.requested || 0,
        _purchasePrice: finalPurchasePrice,
        _subTotal: finalSubTotal,
        _mrp: mrp,
        _b2bPrice: _b2bPrice,
        _cm1: _b2bPrice
          ? CommonService.roundedByDecimalPlace(
              100 - (finalPurchasePrice / _b2bPrice) * 100,
              2
            )
          : null,
        _baseMargin: CommonService.roundedByDecimalPlace(
          p.margins?.bMargin || 0,
          2
        ),
        _schemeMargin: CommonService.roundedByDecimalPlace(
          p.margins?.sMargin || 0,
          2
        ),
        _schemeMarginType: p.margins?.sMarginType || "%",
        _finalMargin: _finalMargin,
        _status: p.status || "NA",
      };
    });

    return productData;
  }
}

export default PoData;
