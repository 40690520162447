class DashboardRbac {
  static getRbacs() {
    return [
      {
        key: "RSP-DASHBOARD",
        name: "Retailer Selling Price Dashboard",
        groups: [
          { key: "AddRSPConfig", name: "Add RSP Configuration" },
          { key: "AddBulkRSPConfig", name: "Add Bulk RSP Configuration" },
          { key: "EditRSPConfig", name: "Edit RSP Configuration" },
          { key: "ViewRSPConfig", name: "View RSP Configuration" },
          { key: "RSPStatusChange", name: "Change RSP Status" },
          { key: "RSPViewStoreProfit", name: "View Store Profit" },
          { key: "RSPViewSKProfit", name: "View SK Profit" },
        ],
      },
      {
        key: "coins_dashboard",
        name: "Coins Dashboard",
        groups: [
          { key: "AddCoins", name: "Add Coins" },
          { key: "EditCoins", name: "Edit Coins" },
          { key: "ViewCoins", name: "View Coins" },
          { key: "DeleteCoins", name: "Delete Coins" },
        ],
      },
      {
        key: "banners_dashboard",
        name: "Banners Dashboard",
        groups: [
          { key: "AddBanner", name: "Add Banner" },
          { key: "EditBanner", name: "Edit Banner" },
          { key: "ViewBanner", name: "View Banner" },
          { key: "DeleteBanner", name: "Delete Banner" },
        ],
      },
      {
        key: "widget_dashboard",
        name: "Widget Dashboard",
        groups: [
          { key: "AddWidget", name: "Add Widget" },
          { key: "EditWidget", name: "Edit Widget" },
          { key: "ViewWidget", name: "View Widget" },
          { key: "DeleteWidget", name: "Delete Widget" },
        ],
      },
      {
        key: "king_slab_dashboard",
        name: "King Slab Dashboard",
        groups: [
          { key: "AddKingSlab", name: "Add King Slab" },
          { key: "EditKingSlab", name: "Edit King Slab" },
          { key: "ViewKingSlab", name: "View King Slab" },
          { key: "DeleteKingSlab", name: "Delete King Slab" },
        ],
      },
      {
        key: "store_offer_dashboard",
        name: "Store Offer Dashboard",
        groups: [
          { key: "AddStoreOffer", name: "Add Store Offer" },
          { key: "EditStoreOffer", name: "Edit Store Offer" },
          { key: "ViewStoreOffer", name: "View Store Offer" },
          { key: "DeleteStoreOffer", name: "Delete Store Offer" },
        ],
      },
    ];
  }
}

export default DashboardRbac;
