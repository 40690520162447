import Swal from "sweetalert2";

import "sweetalert2/src/sweetalert2.scss";

class Loader {
  static showLoader(options = { msg: "" }) {
    Swal.fire({
      text: options.msg || "Please wait",
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  static hideLoader() {
    Swal.close();
  }

  static showPrgsLoader() {
    let el = document.getElementById("app-progress-ldr-container");
    if (el) el.style.width = "100%";
  }

  static hidePrgsLoader() {
    let el = document.getElementById("app-progress-ldr-container");
    if (el) el.style.width = "0px";
  }
}

export default Loader;
